import React from "react";
import { Redirect, Route } from "react-router-dom";
import { userType } from "../../App";

interface PrivateRouteProps {
  children: React.ReactNode;
  user: userType | null;
  wasLoggedOut: boolean;
  [rest: string]: any;
}

const PrivateRoute = ({
  children,
  user,
  wasLoggedOut,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: wasLoggedOut ? "" : `?from=${location.pathname}`,
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
