import React, { useEffect, useState } from "react";
import {
  Card,
  Image,
  Carousel,
  Typography,
  message,
  Divider,
  Spin,
} from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { imageArray } from "../../../constants/Images";

interface ParamsType {
  tokenId: string;
  token: string;
}

const carouselItemStyle: React.CSSProperties = {
  height: "100vh",
  width: "100%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

const carouselItems = imageArray.map((src) => {
  return (
    <div key={src}>
      <div
        style={{ ...carouselItemStyle, backgroundImage: `url(${src})` }}
      ></div>
    </div>
  );
});

const GuestAuth = () => {
  const { tokenId, token } = useParams<ParamsType>();
  const [uploading, setUploading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setUploading(true);
        let res = await axios.post(`/api/guestAuth`, {
          id: tokenId,
          token,
        });
        if (res) {
          window.location.pathname = "/";
          return;
        }
      } catch (e: any) {
        message.error(e.response?.data);
      }
      setUploading(false);
    })();
  }, [tokenId, token]);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <Carousel
        style={{ height: "100%" }}
        autoplay
        dots={false}
        effect="fade"
        autoplaySpeed={5000}
        speed={2000}
      >
        {carouselItems}
      </Carousel>
      <Card
        style={{
          maxWidth: "350px",
          position: "absolute",
          top: "50%",
          left: "50%",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          opacity: 0.95,
        }}
      >
        <Image src="/ERA_MD2.svg" preview={false} style={{ width: "100%" }} />
        <Divider />
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Verificando sesión
        </Typography.Title>
        <Divider />
        {uploading ? (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export default GuestAuth;
