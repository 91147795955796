import { useCallback, useContext } from "react";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { AuthContext, userType } from "../App";
import "./TopBar.css";

export const TopBar = ({ user }: { user: userType | null }) => {
  const auth = useContext(AuthContext);

  const onClick = useCallback(
    (e) => {
      switch (e.key) {
        case "signout":
          auth.signout();
          break;
        default:
          return;
      }
    },
    [auth]
  );

  return (
    <Menu
      className="menuBarTop"
      mode="horizontal"
      onClick={onClick}
      style={{
        float: "right",
        borderBottom: 0,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {user ? <Menu.Item key="user">{user.username}</Menu.Item> : null}
      <Menu.Item
        key="signout"
        title={<LogoutOutlined />}
        icon={<LogoutOutlined />}
      >
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );
};
