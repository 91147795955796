import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useCallback,
  createContext,
} from "react";
import "./App.less";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { TopBar } from "./components/TopBar";
import PrivateRoute from "./components/routes/PrivateRoute";
import { Layout, Image } from "antd";
import { PageLoader } from "./components/utils/PageLoader";
import GuestAuth from "./components/pages/guest/GuestAuth";
const Menu = lazy(() => import("./components/pages/menu/MenuRouter"));
const LogIn = lazy(() => import("./components/pages/login/LogIn"));
const ActivateAccount = lazy(
  () => import("./components/pages/activate/ActivateAccount")
);
const ResetPassword = lazy(
  () => import("./components/pages/reset/ResetPassword")
);

export interface userType {
  id: number;
  username: string;
  level: number;
  station: number;
  multipleStation: boolean;
}

export const AuthContext = createContext<{
  signin: (username: string, password: string) => Promise<boolean>;
  signout: () => Promise<boolean>;
}>({
  signin: async () => true,
  signout: async () => true,
});

function App() {
  const [ready, setReeady] = useState(false);
  const [user, setUser] = useState(null);
  const [wasLoggedOut, setWasLoggedOut] = useState(false);

  const signin = useCallback(
    async (username: string, password: string): Promise<boolean> => {
      try {
        setWasLoggedOut(false);
        let userDB = await axios.post(
          "/api/login",
          { username, password },
          { withCredentials: true }
        );
        if (userDB) {
          setUser(userDB.data);
          return false;
        }
      } catch {}
      return true;
    },
    []
  );

  const signout = useCallback(async (): Promise<boolean> => {
    try {
      let res = await axios.get("/api/logout", { withCredentials: true });
      setWasLoggedOut(true);
      setUser(null);
      if (res) return false;
      return true;
    } catch {}
    setUser(null);
    return true;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let userDB = await axios.get("/api/userData", {
          withCredentials: true,
        });
        if (userDB) {
          setUser(userDB.data);
        }
      } catch {}
      setReeady(true);
    })();
  }, []);

  if (!ready) {
    return <PageLoader message="Recuperando sesión" showLogos={false} />;
  }

  return (
    <AuthContext.Provider value={{ signin, signout }}>
      <Layout>
        {user ? (
          <Layout.Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              backgroundColor: "white",
              paddingRight: 0,
              paddingLeft: "10px",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            <div
              style={{
                float: "left",
                height: "100%",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Image
                height={55}
                width={140}
                src="/ERA_MD2.svg"
                preview={false}
              />
            </div>
            <TopBar user={user} />
          </Layout.Header>
        ) : null}
        <Layout.Content
          style={{ marginTop: user ? "64px" : 0, backgroundColor: "white" }}
        >
          <Suspense fallback={<PageLoader message="" showLogos />}>
            <Router>
              <Switch>
                <Route path="/activate/:userId/:activationToken">
                  <ActivateAccount />
                </Route>
                <Route path="/reset/:userId/:resetPasswordKey">
                  <ResetPassword />
                </Route>
                <Route path="/guest/:tokenId/:token">
                  <GuestAuth />
                </Route>
                <Route path="/login">
                  <LogIn user={user} />
                </Route>
                <PrivateRoute
                  path="/menu"
                  user={user}
                  wasLoggedOut={wasLoggedOut}
                >
                  <Menu user={user} />
                </PrivateRoute>
                <Route path="*">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Router>
          </Suspense>
        </Layout.Content>
      </Layout>
    </AuthContext.Provider>
  );
}

export default App;
